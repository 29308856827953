import { useMiddleware } from "@/store/middleware";
import { isSpecialSubdomain } from "~/utils/helper";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuth();

  const middleware = useMiddleware();
  const host = window.location.host;
  const subdomain = host.split(".")[0];
  const config = useRuntimeConfig();

  //  Check if user is logged in if not refetch user cookie
  if (!auth.isSuccess && !auth.isPending) {
    try {
      await auth.refetch();
    } catch (e) {
      console.log(e);
    }
  }

  const account = ref<{ account_status: string }>({ account_status: "" });
  if (!isSpecialSubdomain(subdomain)) {
    try {
      if (!middleware.account || middleware.accountDataIsStale) {
        await middleware.accountRefetch();
      }
      account.value = middleware.account;
    } catch (e) {
      if (auth.isSuccess) {
        return navigateTo(`${config.public.primaryURL}/switch`, {
          external: true,
        });
      } else {
        return;
      }
    }
    return checkStatus(account.value?.account_status);
  } else {
    if (auth.isSuccess) {
      return navigateTo(`${config.public.primaryURL}/switch`, {
        external: true,
      });
    } else {
      return;
    }
  }

  //if user is logged in return
  function checkStatus(status: string) {
    switch (status) {
      case "ONBOARDING":
        return navigateTo(`${config.public.primaryURL}/onboarding`, {
          external: true,
          replace: true,
        });
      case "ACTIVE":
        return navigateTo("/hub/sessions");
      case "SUSPENDED":
        return navigateTo("/system/suspended", {
          external: true,
        });
      case "PENDING":
        return navigateTo(`/system/pending`, {
          external: true,
        });
      case "CLOSED":
        return navigateTo(`/system/closed`, {
          external: true,
        });
      default:
        return navigateTo(`${config.public.primaryURL}/switch`, {
          external: true,
        });
    }
  }
});
